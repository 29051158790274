@import '../../../styles/mixins.scss';

.button {
    position: relative;
    max-width: 23.5rem;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    background-color: #8E2157;
    border: 2px solid #8E2157;
    text-decoration: none;
    border-radius: 2.375rem;
    padding: 0.875rem;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #fff;
    overflow: hidden;
    transition: 0.4s;
    @include desktop {
        max-width: 24rem;
    }
    @include tablet {
        max-width: 23.5rem;
        padding: 0.75rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
    @media (hover: hover) {
        &:hover:enabled {
            background-color: #fff;
            color: #8E2157;
        }
    }
    &:disabled {
        background-color: #64003A;
        color: rgba(255, 255, 255, 0.3);
    }
}

.outlined {
    border: 2px solid #fff;
    background-color: transparent;
}
.white {
    background-color: #fff;
    border-color: #fff;
    color: #8E2157;
    @media (hover: hover) {
        &:hover:enabled {
            background-color: #8E2157;
            border-color: #8E2157;
            color: #fff;
        }
    }
    &:disabled {
        background-color: #64003A;
        border-color: #64003A;
        color: rgba(255, 255, 255, 0.3);
    }
}
