@import '../../../styles/mixins.scss';

.wrap {
    padding: 1rem;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 12px;
    margin-top: auto;
    @media (max-aspect-ratio: 1/1) and (min-width: 1025px) {
        margin-top: 0;
    }
    @include desktop {
        align-items: center;
        gap: 4px;
        padding: 1rem 0.125rem;
        margin-top: 2rem;
    }
    @include tablet {
        margin-top: 0;
    }
}

.links {
    text-wrap: wrap;
    text-align: center;
    align-items: center;
    @include tablet {
        justify-content: center;
        flex-direction: row;
        padding: 0 1rem;
    }

    &.verticalAlignment {
        @include tablet {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            margin-top: 29px;
            a {
                line-height: 18px;
                border: none !important;
                padding: 0;
            }
        }
    }

    a {
        font-weight: 300;
        font-size: 1rem;
        color: #fff;
        text-decoration: none;
        text-align: center;
        padding: 0 16px 2px;
        border-right: solid 1px rgba(255, 255, 255, 0.7);
        &:first-of-type {
            padding-left: 0;
        }
        &:last-of-type {
            padding-right: 0;
            border: none;
        }
        @include tablet {
            font-size: 0.75rem;
            padding: 0 0.5rem;
            font-weight: 400;
        }
        &:hover {
            text-decoration: underline;
        }
    }
}
.qatarLink {
    font-weight: 600;
    width: fit-content;
    font-size: 20px;
}

.hide {
    display: none;
}
.line {
    width: 1rem;
    height: 1rem;
    border-right: 1px solid rgba(255, 255, 255, 0.7);
}

[dir='rtl'] {
    .links {
        right: initial;
        left: 0;
        a {
            border-right: none;
            border-left: solid 1px rgba(255, 255, 255, 0.7);
            &:first-of-type {
                padding-right: 0;
                padding-left: 1rem;
                @include tablet {
                    padding-left: 0.5rem;
                }
            }
            &:last-of-type {
                padding-left: 0;
                padding-right: 1rem;
                border: none;
                @include tablet {
                    padding-right: 0.5rem;
                }
            }
        }
    }
}
