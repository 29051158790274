@import '../../../styles/mixins.scss';

.link {
    max-width: 23.5rem;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    background-color: #fff;
    text-decoration: none;
    border-radius: 2.375rem;
    padding: 0.875rem;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #780044;
    transition: 0.4s;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    @include tablet {
        padding: 0.75rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
        gap: 8px;
    }
    &:hover {
        background-color: #780044;
        color: #fff;
        text-decoration: none;
    }

    img {
        width: 1.7rem;
        height: 1.7rem;
        @include tablet {
            width: 1.3rem;
            height: 1.3rem;
        }
    }
}

.outlined {
    border: 2px solid #fff;
    color: #fff;
    background-color: transparent;
    &:hover {
        background-color: #fff;
        color: #780044;
    }
}
.white {
    background-color: #fff;
    color: #fff;
    color: #780044;
    &:hover {
        background-color: #780044;
        border-color: #780044;
        color: #fff;
    }
}
.pink {
    background-color: #fff;
    color: #e42058;
    &:hover {
        background-color: #e42058;
        color: #fff;
        img {
            filter: brightness(0) invert(1);
        }
    }
}
