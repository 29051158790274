@import '../../styles/mixins.scss';

.dialog {
    :global {
        .MuiPaper-root {
            background-color: #f2f3fa;
        }
    }
}
.wrap {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6.5rem 1rem 9.625rem;
    color: #4a525d;
    @include desktop {
        flex-direction: column-reverse;
        justify-content: center;
        padding: 3.75rem 1.25rem 1rem;
    }
}

.exitButton {
    position: absolute;
    top: 3.75rem;
    right: 1rem;
    width: 2.125rem;
    height: 2.125rem;
    transition: 0.4s;
    @include tablet {
        top: 1.25rem;
        width: 1.25rem;
        height: 1.25rem;
    }
    &:hover {
        transform: scale(1.1);
    }
    img {
        display: flex;
        width: 100%;
        height: 100%;
    }
}

.leftCol {
    width: 47%;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    @include desktop {
        width: 100%;
        margin: 0 0 3.125rem;
    }
}
.row {
    p {
        margin: 0 0 1.5rem;
        @include tablet {
            margin: 0 0 1rem;
        }
    }
}
.rowTitle {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0 0 0.5rem;
    color: #4a525d;
    @include tablet {
        gap: 0.3125rem;
        margin: 0 0 0.125rem;
    }
}
.icon {
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    @include tablet {
        width: 1.5625rem;
        height: 1.5625rem;
    }
    img {
        width: 3rem;
        height: 3rem;
        @include tablet {
            width: 1.625rem;
            height: 1.625rem;
        }
    }
}
.img {
    display: flex;
    width: 100%;
    img {
        width: 100%;
    }
}

.rightCol {
    width: 49%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 0.875rem;
    @include desktop {
        width: 100%;
        padding: 0 1.125rem;
    }
    h1,
    h2 {
        white-space: pre-wrap;
        color: #4a525d;
        margin: 0 0 1rem;
        @include tablet {
            margin: 0 0 0.625rem;
        }
    }
    p {
        margin: 0 0 2.5rem;
        @include tablet {
            margin: 0 0 1.875rem;
        }
    }
    button {
        @include desktop {
            display: none;
        }
    }
}

.rightColItalian {
  @extend .rightCol;
  padding: 0;
    max-width: 623px;

    @include desktop {
        max-width: 650px;
        h1 {
            max-width: 650px;
        }
        p {
            max-width: 650px;
        }
    }

    @include tablet {
        h1 {
            max-width: 360px;
        }
        p {
            max-width: 360px;
        }
    }
}

.rightColChinese {
  @extend .rightCol;
  padding: 0;
}

.mobileButton {
    display: none;
    @include tablet {
        display: inline-flex;
        margin: auto 0 0 0;
    }
}

[dir='rtl'] {
    .exitButton {
        right: initial;
        left: 1rem;
    }
}

.body1French {
  white-space: normal;

  @include mobile {
    white-space: break-spaces;
  }
}
