@import '../../styles/mixins.scss';

.wrap {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8rem;
    margin: auto;
    @media (max-aspect-ratio: 1/1) {
        margin-top: auto;
        margin-bottom: auto;
    }
    @include desktop {
        // position: relative;
        flex-direction: column;
        justify-content: flex-start;
        gap: 1.625rem;
        // margin: -4rem 0 0 0;
        padding: 1rem 1rem 1.25rem;
        overflow: hidden;
    }
}

.frenchReadyContainer {
    gap: 6rem;
    @include desktop {
        gap: 1.625rem;
    }
}

.italianReadyContainer {
    gap: 3rem;
    @include desktop {
        gap: 1.625rem;
    }
}

.progressContainer {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    @media (max-aspect-ratio: 1/1) {
        justify-content: center;
    }
    @include tablet {
        justify-content: flex-end;
    }
    gap: 40px;
}

.readyContainer {
    @media (max-aspect-ratio: 1/1) {
        justify-content: center;
    }
    @include tablet {
        margin-top: 2rem;
    }
    [dir='rtl'] & {
        flex-direction: row-reverse;
        @include desktop {
            flex-direction: column;
            justify-content: flex-start;
        }
    }
}

.mobileTitle {
    display: none;
    color: #fff;

    &.mobileTitleVisible {
        @include desktop {
            display: flex;
        }
    }

    &.mobileTitleItalian {
        @include desktop {
            max-width: 350px;
            margin: 0 auto;
        }
    }
}

.mobileSubTitle {
    margin-top: -1rem;
    white-space: pre-wrap;
    text-align: center;
    line-height: 1.35;
    font-size: 14px;
    font-weight: 300;
}

.videobox {
    width: fit-content;
    aspect-ratio: 16/9;
    position: relative;
    border-radius: 0.75rem;
    overflow: hidden;
    will-change: transform;
    z-index: 1;
    @media (hover: hover) {
        &:hover {
            .playBtn,
            .videoVolume {
                opacity: 1;
            }
        }
    }
    video {
        object-fit: cover;
        position: absolute;
        width: auto;
        height: 100%;
        min-height: 100%;
        min-width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .playBtn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        width: 7.5rem;
        height: 7.5rem;
        z-index: 2;
        transition: opacity 0.2s, width 0s, height 0s;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .hide {
        opacity: 0;
        height: 100%;
        width: 100%;
        transition: width ease-in 1.6s, height ease-in 1.6s;
        @media (hover: hover) {
            width: 7.5rem;
            height: 7.5rem;
            transition: opacity 0.2s, width 0s, height 0s;
        }
    }
    .maximizeBtn {
        position: absolute;
        right: 24px;
        bottom: 24px;
        display: flex;
        width: 32px;
        height: 32px;
        z-index: 9999;
        transition: 0.2s, right 0s, bottom 0s, width 0s, height 0s;
        @include desktop {
            right: 12px;
            bottom: 12px;
            width: 18px;
            height: 18px;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
    .maximizedMaximizeBtn {
        @include desktop {
            right: 24px;
            bottom: 24px;
            width: 24px;
            height: 24px;
        }
    }
}
.readyVideo {
    height: calc(100dvh - 214px);
    margin-left: 11rem;
    min-height: 30rem;
    max-height: 555px;
    max-width: 38%;
    aspect-ratio: 9/16;
    position: relative;
    @include largetTablet {
        margin-left: 2rem;
    }
    @include desktop {
        height: 0;
        min-height: initial;
        max-height: 30rem;
        width: 100%;
        max-width: 43%;
    }
    @include desktop {
        width: 72%;
        padding: 0 0 78.5%;
        margin: 0 auto;
        border-radius: 0.75rem;
        border: 0.0625rem solid #780044;
    }
    &::after {
        content: none;
    }
    .playBtn {
        @include desktop {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translate(0, 0);
            display: flex;
            align-items: center;
            justify-content: center;
            width: initial;
            height: initial;
            img {
                width: 2rem;
                height: 2rem;
            }
            &.paused {
                img {
                    opacity: 0;
                }
            }
        }
    }
}
.isMaximized {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    background-color: #000;
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    display: flex;
    align-items: center;
    max-height: initial;
    max-width: initial;
    aspect-ratio: initial;
    z-index: 3;
    video {
        object-fit: initial;
        position: initial;
        top: initial;
        left: initial;
        transform: none;
        height: auto;
        width: auto;
        max-width: 100%;
        max-height: 100%;
        min-width: initial;
        min-height: initial;
        margin: auto;
    }
    .playBtn {
        // display: none;
    }
}
.hideVideoControls {
    @media (hover: hover) {
        button {
            opacity: 0 !important;
            @include desktop {
                opacity: 1 !important;
            }
        }
    }
}

.descbox {
    width: 720px;
    max-width: 100%;
    color: #fff;
    text-align: center;
    z-index: 1;
    margin: -2rem 0 0 0;
    @include desktop {
        width: 100%;
        margin: 0;
    }
    img {
        width: 15.75rem;
        height: auto;
        margin: 0 0 2.625rem;
        @include desktop {
            margin: 0 0 1rem;
        }
    }
    p {
        margin: 0 0 3.75rem;
        @include desktop {
            margin: 0 0 1.25rem;
        }
        &:global {
            &.h1 {
                margin: 0 0 1rem;
                @include desktop {
                    margin: 0 0 0.375rem;
                }
            }
        }
    }
    form {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        text-align: left;
        @include desktop {
            flex-direction: column;
            gap: 1.25rem;
        }
    }
}
.readyDescbox {
    width: 35%;
    padding-top: 0;

    @include desktop {
        width: 100%;
        p {
            display: none;
        }
    }
}

.frenchReadyDescbox {
    width: 45%;
    @include desktop {
        width: 100%;
        p {
            display: none;
        }
    }
}

.italianReadyDescbox {
    width: 60%;
    max-width: 655px;
    @include desktop {
        width: 100%;
        p {
            display: none;
        }
    }
}

.germanReadyDescbox {
    width: 550px;

    p {
      width: 550px;
      max-width: 550px;
    }

    @include desktop {
        width: 100%;
        p {
            display: none;
        }
    }
}

.inputBox {
    width: calc(100% - 10.8125rem);
    display: flex;
    gap: 0.375rem;
    flex-direction: column;
    @include desktop {
        width: 100%;
    }
    label {
        font-weight: 300;
        font-size: 1.5rem;
        line-height: 2.25rem;
        @include desktop {
            font-size: 0.875rem;
            line-height: 1.25rem;
        }
    }
    input {
        background-color: transparent;
        border-bottom: 0.0625rem solid #fff;
        border-radius: 0;
    }
}

.submitButton {
    width: 10.3125rem;
    @include desktop {
        width: 100%;
    }
}

.btnGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    margin: 3.75rem 0 0 0;
    @include desktop {
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1rem 0.625rem;
        margin: 0;
    }
}
.button {
    width: 100%;
    @include desktop {
        width: calc(50% - 0.3125rem);
    }
}

.notifyModalButton {
    @include tablet {
        width: 100%;
        max-width: 23.5rem;
        min-width: unset;
    }
    width: fit-content;
    min-width: 23.5rem;
    max-width: unset;
    padding: 0.75rem 1.5rem;
}

.body1 {
    max-width: 390px;
    margin: 0 auto !important;
    text-align: center;
}

.body1FrenchLanguage {
    @extend .body1;
    max-width: unset;
}

.createNew {
    color: #fff;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 2.25rem;
    margin-top: -0.4rem;
    text-decoration: underline;
    padding: 0;
    @include desktop {
        font-size: 0.875rem;
        line-height: 1.25rem;
        margin: 0;
    }
    &:hover {
        text-decoration: underline;
    }
}
.progressTitle {
    @include tablet {
        font-size: 10px;
    }
    &:global {
        &.body2 {
            margin: 2rem 0 0.25rem;
        }
    }
}
.progressLine {
    margin: 0.5rem 0 0 0;
    &:global {
        &.MuiLinearProgress-root {
            height: 0.1875rem;
            background: #cccccc;
            border-radius: 0.625rem;
            span {
                background: #fff;
            }
        }
    }
}
// .progressBarContainer {
//     display: inline-block;
//     width: 500px;
//     max-width: 100%;
//     background-color: #CCCCCC;
//     height: 6px;
//     overflow: hidden;
//     border-radius: 10px;
//     @include desktop {
//         height: 3px;
//     }
// }

// .progressBarLine {
//     background-color: white;
//     height: 100%;
//     border-radius: 10px;
// }

.embedVideo {
    aspect-ratio: 16 / 9;
    border: none;
}

.qatarLinkWrap {
    flex: initial;
    margin-top: 1rem;
    margin-bottom: 0;
    @include desktop {
        text-align: center;
        margin-bottom: -2rem;
        padding-top: 0;
        padding-bottom: 0;
    }
    @include tablet {
        margin-bottom: -0.5rem;
    }
}
.qatarLink {
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    font-size: 20px;
    margin: 0 0 0 auto;
    @media (max-aspect-ratio: 1/1) {
        flex: unset;
    }
    @include desktop {
        color: rgba(255, 255, 255, 0.6);
        margin: 0;
    }
    @include tablet {
        font-size: 14px;
    }
}

[dir='rtl'] {
    label {
        text-align: right;
    }

    .progressLine {
        transform: scale(-1, 1);
    }
    .qatarLink {
        margin: 0 auto 0 0;
        @include desktop {
            margin: 0;
        }
    }
}
