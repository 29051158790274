@import '../../../styles/mixins.scss';

.videoVolume {
    position: absolute;
    bottom: 24px;
    left: 24px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    opacity: 0;
    transition: 0.2s;
    @include tablet {
        display: none;
    }
}
.slider {
    &:global {
        &.MuiSlider-root {
            height: 100px;
            color: #fff;
        }
    }
}
