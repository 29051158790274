.modalContainer {
  max-width: 525px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 36px;
  align-items: center;
  text-align: center;
}

.closeBtn {
  outline: none;
  appearance: none;
  background-color: rgba(255, 255, 255, 0.1);
  width: fit-content;
  height: fit-content;
  padding: 12px 17px;
  border-radius: 8px;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  gap: 18px;
  color: white;
  align-items: center;
}

.headerSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  color: white;
  h2 {
    font-weight: 700;
    font-size: 20px;
  }
}

.stepsSection {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  gap: 12px;
  .step {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    width: fit-content;
    text-align: left;
    [dir='rtl'] & {
      text-align: right;
    }
  }
}

.breakInstructions {
  font-size: 20px;
  font-weight: 700;
  color: white;
}

.tapHereSection {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 12px;
    color: white;
    img {
      height: 30px;
      width: auto;
    }
    p {
        font-size: 14px;
        font-weight: 700;
    }
    [dir='rtl'] & {
      align-items: flex-start;
    }
}
