@import '../../styles/mixins.scss';

.wrap {
    color: #fff;
    padding: 0 9.625rem;
    @include tablet {
        padding: 0 2rem;
    }
}

.title {
    margin: 2rem 0 1.5rem;
}

.text {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5rem;
    @include tablet {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    p {
        margin-bottom: 1.5rem;
    }
}

[dir='rtl'] .wrap {
    text-align: right;
}
