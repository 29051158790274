@import '../../styles/mixins.scss';

.wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: auto;
    @include tablet {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
        padding: 1rem;
        overflow: hidden;
    }
    &.aligned {
        align-items: center;
    }
}

.backBtn {
    position: absolute;
    top: 3.0625rem;
    left: 50%;
    transform: translate(calc(-50% - 622px), 0);
    display: flex;
    width: 2.3125rem;
    height: 2.3125rem;
    z-index: 2;
    @include large {
        left: 1rem;
        transform: none;
    }
    @include tablet {
        top: 1.75rem;
        height: 1.375rem;
        width: 1.375rem;
    }
}

.slider {
    width: 47%;
    margin: auto 0;
    @include tablet {
        width: 100%;
        overflow: visible;
    }
    :global {
        .swiper-wrapper {
            flex-direction: column;
            gap: 3.4375rem;
            @media (min-width: 769px) {
                transform: none !important;
            }
            @include tablet {
                flex-direction: row;
                gap: 0;
            }
        }
    }
}
.slide {
    display: flex;
    flex-direction: column;
    background-color: #3c0026;
    border-radius: 0.75rem;
    height: auto;
    overflow: hidden;
    @media (min-width: 769px) {
        width: 100% !important;
    }
}

.greatPhotoMobileMsg {
    display: none;
    color: #fff;
    font-size: 18px;
    text-align: center;
    padding: 1.625rem 2.5rem 0.375rem;
    @include tablet {
        max-width: 320px;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }
    @include mobile {
        font-size: 14px;
    }
}
.info {
    position: absolute;
    top: 1rem;
    left: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 0.625rem 1rem;
    color: #fff;
    text-align: center;
    background: #1f212be5;
    border-radius: 0.75rem;
    z-index: 2;
    @include desktop {
        top: 0.75rem;
        left: 0.75rem;
        gap: 0.125rem;
        padding: 0.5rem 0.75rem;
        border-radius: 0.5rem;
        display: flex;
        max-width: 173px;
    }
    @include mobile {
      max-width: 173px;
    }
}
.frenchInfo {
    @include desktop {
        max-width: 130px;
    }
}
.name {
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.6875rem;
    @include desktop {
        font-size: 1.0625rem;
        line-height: 1.5rem;
    }
}
.subtitle {
    font-weight: 300;
    line-height: 1.5rem;
    @include tablet {
        font-size: 0.875rem;
    }
}
.imgRow {
    position: relative;
    width: 100%;
    &.doubleImg {
        display: flex;
        justify-content: center;
        gap: 1.875rem;
        padding: 1.625rem;
        @include tablet {
            flex: 1;
            gap: 0.5rem;
            padding: 1rem;
        }
    }
}
.imgBox {
    position: relative;
    height: 14.375rem;
    // padding: 0 0 38.115%;
    overflow: hidden;
    @include tablet {
        padding: 0 0 88%;
        height: initial;
    }
    &.smallImgBox {
        width: 28%;
        padding: 0 0 32%;
        height: initial;
        border: 0.0625rem solid #780044;
        border-radius: 0.625rem;
        @include tablet {
            width: calc(50% - 0.25rem);
            height: 100%;
        }
        @include mobile {
            padding: 0 0 58%;
        }
    }
    img {
        object-fit: cover;
        position: absolute;
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.box {
    position: sticky;
    top: 3.75rem;
    max-width: 53%;
    height: fit-content;
    margin: auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    padding: 0 5rem;
    width: inherit;
    @include desktop {
        padding: 0 1.5rem;
    }
    @include tablet {
        position: initial;
        top: initial;
        transform: translate(0, 0);
        flex: initial;
        max-width: initial;
        min-height: initial;
        width: 100%;
        margin: 0;
    }
    // &.aligned {
    //     position: relative;
    //     top: initial;
    //     transform: translate(0, 0);
    //     margin: 0;
    // }
    .greatPhoto {
        margin: 0 0 60px;
        @include tablet {
            margin: 0;
            display: none;
        }
    }
    .greatPhotoChinese {
      @extend .greatPhoto;

      @include desktop {
        font-size: 30px;
      }
    }
    .uploadYourPhoto {
        margin-bottom: 16px;
    }
    h1 {
        margin: 0 0 1rem;
    }
    :global {
        .body1 {
            max-width: 630px;
            margin: 0 0 60px;
            @include tablet {
                margin: 0;
            }
        }
    }
    button {
        @include tablet {
            display: none;
        }
    }
}


.boxFrench {
    padding: 0 3rem;
    max-width: 55%;
    @include tablet {
      max-width: 350px;
      padding: 0;
    }
}

.boxPortuguese {
  @extend .box;
  max-width: 59%;

  @include tablet {
    max-width: 350px;
  }
}

.boxGerman {
  padding: 0 1rem 0 1rem;
}

.body1Italian {
  @include tablet {
    width: 290px;
  }
}

.body1German {
  max-width: 550px;

  @include mobile {
    width: 300px;
    max-width: 300px;
  }
}

.uploadButton {
    margin: 1.5rem 0 0;
    @include tablet {
        margin: 0.75rem 0 0;
    }
}

.mobileBox {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    @include tablet {
        display: flex;
    }
}

.cameraBox {
    height: calc(100dvh - 220px);
    width: 100%;
    max-width: calc(1 / 1.1 * (100dvh - 220px));
    aspect-ratio: 1/1.1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../assets/icons/img-corners.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 0.875rem;
    @include tablet {
        width: 100%;
        height: initial;
    }
    :global {
        .display-error {
            width: 100%;
            &:before {
                content: 'Camera error: Permission denied';
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24px;
                padding: 16px;
                text-align: center;
            }
            h1 {
                display: none;
            }
        }
        .react-html5-camera-photo {
            height: 100%;
            width: 100%;
        }
        #outer-circle {
            background-image: url('../../assets/icons/camera-btn.svg');
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            width: 4rem;
            height: 4rem;
            cursor: pointer;
            * {
                display: none;
            }
        }
    }

    img {
        width: 100%;
    }
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.5rem;
    }
}

.cameraScreenContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 4rem;
}

.submitBtn {
    &:disabled {
        pointer-events: none;
    }
}
.loaderBox {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #64003a;
    img {
        width: 2rem;
        height: 2rem;
    }
}

[dir='rtl'] {
    .backBtn {
        transform: scale(-1, 1) translate(calc(-50% - 588px), 0);
        @include large {
            left: initial;
            right: 1rem;
            transform: scale(-1, 1);
        }
    }
    .info {
        right: 1rem;
        left: initial;
        @include tablet {
            left: 50%;
        }
    }
}
