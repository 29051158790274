@import '../styles/mixins.scss';

body {
    margin: 0;
    font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #780044;
    [dir='rtl'] & {
        font-family: 'Almarai', -apple-system, BlinkMacSystemFont, 'Segoe UI',
            'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', sans-serif;
    }

    &.fixed {
        overflow: hidden;
        height: 100%;
    }
}

* {
    [dir='ltr'] & {
        font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
            'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', sans-serif !important;
    }
    [dir='rtl'] & {
        font-family: 'Almarai', -apple-system, BlinkMacSystemFont, 'Segoe UI',
            'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', sans-serif !important;
    }
}

.main {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 9.625rem 0 1rem 0;
    background-image: url(../assets/bg-1.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    // background-attachment: fixed; Create a bug when toggle backgrounds on ios
    @include tablet {
        padding: 4rem 0 0 0;
        min-height: 100dvh;
    }
    &.createPage {
        @include tablet {
            padding: 1.5rem 0 1rem 0;
        }
    }
    &.secondaryBg {
        background-image: url(../assets/bg-2.png);
    }
    &.isIOS13 {
        background-image: url(../assets/bg-1.gif);

        &.secondaryBg {
            background-image: url(../assets/bg-2.gif);
        }
    }
    &.festiveModeOn {
        background-position: center;
        background-image: url(../assets/festive-background.png);
    }
    &.festiveModeTablet {
        background-position: center;
        background-image: url(../assets/festive-background-tablet.png);
    }
}
.wrap {
    width: 100%;
    max-width: 1316px;
    padding: 0 16px;
    margin: 0 auto;
}

.h1 {
    font-weight: 500;
    font-size: 40px;
    line-height: 54px;
    white-space: pre-wrap;
    @include tablet {
        font-size: 22px;
        line-height: 30px;
    }
}
.h2 {
    font-weight: 500;
    font-size: 32px;
    line-height: 44px;
    @include tablet {
        font-size: 17px;
        line-height: 24px;
    }
}

.body1 {
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    white-space: pre-wrap;
    word-break: keep-all;
    @include tablet {
        font-size: 14px;
        line-height: 20px;
    }
}
.MuiTypography-body1 {
    word-break: keep-all;
    @media screen and (max-width: 320px) {
        word-break: break-all;
    }
}

*:focus-visible {
    // make outline visible on focus
    outline: 2px solid #8c8fee !important;
    transition: none;
}

#outer-circle:focus-visible {
    outline-width: 3px !important;
}

.rws-backdrop.web-share-fade {
    justify-content: center;
    @include tablet {
        justify-content: flex-end;
    }
}

.MuiDialog-root .MuiModal-backdrop {
    backdrop-filter: blur(8px);
}

.Mui-error {
    [dir='rtl'] & {
        text-align: right;
    }
}

.close-modal-icon {
    right: 8px;
    [dir='rtl'] & {
        right: auto;
        left: 8px;
    }
}

#display-error {
    display: none;
}
#display-error::before {
    content: var(--before-content);
}

.react-html5-camera-photo {
    #white-flash {
        top: 0;
    }
}

.qatar-legal {
    overflow: hidden;
    .agreements-dialog {
        a {
            color: #4a525d !important;
        }
        table,
        th,
        td {
            border: 1px solid #4a525d !important;
        }
    }
    a {
        color: white;
    }
    .text-container {
        .text {
            ol.decimal {
                list-style: decimal;
                li {
                    list-style: decimal;
                }
            }

            ol.alpha {
                counter-reset: list;
                list-style: none;
                li {
                    list-style: none;
                    font-size: 1rem;
                    font-weight: 300;
                    position: relative;
                }
                li:before {
                    content: '(' counter(list, lower-alpha) ') ';
                    counter-increment: list;
                    position: absolute;
                    [dir='ltr'] & {
                        left: -1.4rem;
                    }
                    [dir='rtl'] & {
                        right: -1.4rem;
                    }
                }
            }

            ol.roman {
                counter-reset: list;
                list-style: none;
                li {
                    margin-top: 0.5rem;
                    list-style: none;
                    font-size: 1rem;
                    font-weight: 300;
                    position: relative;
                }
                li:before {
                    content: '(' counter(list, lower-roman) ') ';
                    counter-increment: list;
                    position: absolute;
                    [dir='ltr'] & {
                        left: -1.4rem;
                    }
                    [dir='rtl'] & {
                        right: -1.4rem;
                    }
                }
            }

            ol li {
                margin: 0 2.5rem;
                padding: 0 0.5rem;
                font-weight: 500;
                font-size: 1.25rem;
            }

            a {
                color: #fff;
                text-decoration: underline;
                text-underline-offset: 4px;
            }
        }

        h3 {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: 1.5rem;
        }

        table,
        td {
            border: 1px solid white;
        }

        table td {
            padding: 8px 16px 24px 8px;
            vertical-align: top;
        }

        table td:first-child {
            font-weight: 600;
            width: 18%;
        }
    }
}

.cookie-inner-container {
    padding-left: 25px;
    padding-right: 25px;
    p {
        font-size: 15px;
        line-height: 24px;
    }
}

.grecaptcha-badge {
    visibility: hidden;
}
