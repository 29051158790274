@import '../../styles/mixins.scss';

.slideActions {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0;
    @include tablet {
        flex-direction: row-reverse;
        padding: 1rem 0;
    }
    button,
    label {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.125rem;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 2rem;
        color: #fff;
        @include desktop {
            font-size: 1.125rem;
        }
        @include large {
            white-space: break-spaces;
        }
        @include mobile {
            gap: 0;
            font-weight: 300;
            font-size: 0.875rem;
            line-height: 1.4;
            white-space: break-spaces;
        }
        img {
            width: 3.25rem;
            height: 3.25rem;
            @include desktop {
                width: 2.5rem;
                height: 2.5rem;
            }
            @include mobile {
                width: 2rem;
                height: 2rem;
            }
        }
    }
}
.slideActionsBox {
    margin-top: auto;
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    border-right: 0.0625rem solid #fff;
    padding: 0.875rem 0;
    [dir='rtl'] & {
        border-right: none;
        border-left: 0.0625rem solid #fff;
    }
    @include tablet {
        padding: 0.18rem 0;
        border-right: none;
        border-left: 0.0625rem solid #fff;
        [dir='rtl'] & {
            border-right: 0.0625rem solid #fff;
            border-left: none;
        }
    }
    &:last-of-type {
        border: none;
    }
    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: transparent;
        left: 0;
        z-index: -10;
    }
    input[type='file']::file-selector-button {
        display: none;
    }
}
.slideActionsLabel {
    cursor: pointer;
    text-align: center;
    z-index: 100;
}
