@import '../../styles/mixins.scss';

.wrap {
    position: relative;
    //flex: 1;
    display: flex;
    align-items: center;
    margin: auto;
    padding: 0 10.25rem;
    @include largetTablet {
        padding: 0 3.5rem;
    }
    @include desktop {
        padding: 0 1rem;
    }
    @media (max-aspect-ratio: 1/1) and (min-width: 1025px) {
        margin-top: auto;
        margin-bottom: auto;
    }
    @include tablet {
        margin: -4rem 0 0 0;
    }
}

.slider {
    @include tablet {
        height: 100dvh;
        min-height: 23.75rem;
        margin: 0 -1rem;
    }
}

.slide {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem 0;
    justify-content: space-between;
    @include tablet {
        height: 100%;
        padding: 0 1rem;
    }
    &:global {
        &.swiper-slide-active {
            z-index: 2;
            * {
                opacity: 1;
            }
            & > div:nth-of-type(2) {
                p {
                    transform: translate(0, 0);
                }
                h1,
                h2 {
                    transform: translate(0, 0);
                }
                a {
                    transform: scale(1);
                }
            }
        }
    }
    * {
        opacity: 0;
        transition: 0.4s;
    }
}

.videobox {
    // width: 38%;
    // padding: 0 0 66%;
    height: calc(100dvh - 214px);
    max-height: 34.6875rem;
    min-height: 28rem;
    max-width: 38%;
    aspect-ratio: 9/16;
    // margin: 0 auto;
    position: relative;
    border-radius: 0.75rem;
    overflow: hidden;
    will-change: transform;
    z-index: 1;
    @include desktop {
        min-height: initial;
        max-width: initial;
        margin: 0 auto;
        // width: 100%;
        // height: 50rem;
        // padding: 0;
    }
    @include tablet {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        border-radius: 0;
        max-height: initial;
        max-width: initial;
        aspect-ratio: initial;
    }
    &:hover {
        .playBtn,
        .videoVolume {
            opacity: 1;
        }
    }
    &:after {
        content: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(100, 0, 58, 0.6);
        @include tablet {
            content: '';
        }
    }
    &.festiveModeOn {
        &:after {
            background-image: url(../../assets/festive-background-mobile.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 0 center;
        }
    }
    video {
        object-fit: cover;
        position: absolute;
        width: auto;
        height: 100%;
        min-height: 100%;
        min-width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .playBtn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        width: 7.5rem;
        height: 7.5rem;
        opacity: 0;
        transition: 0.2s;
        @include tablet {
            display: none;
            opacity: 1;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
    .closeBtn {
        display: flex;
        position: absolute;
        top: 1.25rem;
        right: 1.25rem;
        z-index: 3;
        opacity: 0;
        visibility: hidden;
        &.visible {
            opacity: 1;
            visibility: visible;
        }
    }
    .videoVolume {
        opacity: 0;
    }
}

.descbox {
    width: 56%;
    color: #fff;
    text-align: center;
    @include desktop {
        position: relative;
        width: 100%;
    }
    @include tablet {
        position: unset;
        z-index: 2;
        transition: none;
    }

    &.canUseFestiveMode {
        margin-top: 124px;
        @include tablet {
            margin-top: 0;
        }
    }

    &.festiveModeOn {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    p {
        font-weight: 300;
        font-size: 1.5rem;
        line-height: 2.25rem;
        margin: 0;
        transform: translate(0, -2.5rem);
        @include tablet {
            font-size: 0.875rem;
            line-height: 1.25rem;
        }
    }
    h1,
    h2 {
        text-align: center;
        font-weight: 500 !important;
        font-size: 2.5rem;
        line-height: 3.375rem;
        padding: 0.5rem 0;
        transform: translate(0, -3.125rem);
        @include desktop {
            position: absolute;
            line-height: 3.1rem;
            width: 100%;
        }
        @include tablet {
            position: relative;
            font-size: 1.375rem;
            line-height: 1.875rem;
        }
        @include mobile {
            max-width: 325px;
            margin: 0 auto;
        }
    }
    button {
        display: none;
        width: 2rem;
        height: 2rem;
        margin: 1rem auto;
        @include tablet {
            display: flex;
        }
        img {
            z-index: 1;
        }
    }
    a {
        margin: 136px 0 44px;
        transform: scale(0.9);
        @include desktop {
            margin: 250px 0 0;
        }
        @include tablet {
            position: absolute;
            bottom: 1rem;
            left: 1rem;
            right: 1rem;
            max-width: initial;
            width: calc(100% - 2rem);
            font-size: 0.875rem;
            padding: 0.75rem;
            margin: 0;
            z-index: 2;
        }
    }
}
.descboxHidden {
    @include tablet {
        display: none;
    }
}

.thumbSliderBox {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translate(0, -50%);
    width: calc(61% - 12.5rem);
    z-index: 2;

    &.canUseFestiveMode {
        top: 63%;
        @include desktop {
            top: initial;
            bottom: 8%;
        }
        @include tablet {
            top: initial;
            bottom: 3rem;
        }
    }

    @include large {
        right: 7.25rem;
    }
    @include desktop {
        right: 1rem;
        width: calc(100% - 2rem);
        bottom: 8%;
        top: initial;
    }
    @include tablet {
        top: initial;
        bottom: 3rem;
        display: flex;
    }
}

.thumbSlider {
    position: relative;
    padding: 0.25rem 0;
    margin: 0 3.75rem;
    @include tablet {
        padding: 0.25rem 0;
        width: initial;
        margin: 0 auto;
    }
    :global {
        .swiper-button-prev,
        .swiper-button-next {
            @include tablet {
                display: none;
            }
            &:after {
                color: #fff;
                font-size: 1.25rem;
            }
        }
    }
}

.parentSlider {
    position: relative;
}

.slideArrow {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    color: #fff;
    width: 1.25rem;
    height: 2.3125rem;
    @include desktop {
        display: none;
    }
}

.prevScene {
    left: 0;
    [dir='rtl'] & {
        left: initial;
        right: -2rem;
        transform: rotate(180deg) translate(0, 50%);
    }
    @include large {
        [dir='rtl'] & {
            right: -0.5rem;
        }
    }
}

.nextScene {
    right: 0;
    [dir='rtl'] & {
        right: initial;
        left: -2rem;
        transform: rotate(180deg) translate(0, 50%);
    }
    @include large {
        [dir='rtl'] & {
            left: -0.5rem;
        }
    }
}

.thumbSliderSlide {
    font-weight: 500;
    font-size: 1.0625rem;
    line-height: 1.5rem;
    color: #fff;
    border-radius: 2.5rem;
    padding: 0.5rem;
    border: 1px solid transparent;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    transition: 0.4s;
    user-select: none;
    @include large {
        font-size: 1.25rem;
    }
    @include tablet {
        width: auto;
        font-size: 0.875rem;
        border-radius: 2.375rem;
        padding: 0.5rem 1rem;
    }
    &:global {
        &.swiper-slide-thumb-active {
            border: 1px solid #fff;
            color: #fff;
            @include tablet {
                color: #fff;
            }
        }
    }
}
.thumbSliderSlideFestive {
    &:global {
        &.swiper-slide-thumb-active {
            border-color: #e42058;
        }
    }
}

.above {
    @include tablet {
        z-index: 3;
        &:after {
            // opacity: 0;
            content: none;
        }
    }
}
.fixed {
    :global {
        .swiper-wrapper {
            justify-content: center;
        }
    }
}
.hidden {
    display: none;
}

.toggleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 14%;
    right: 10.5%;
    transform: translate(0, -50%);
    width: calc(61% - 12.5rem);
    z-index: 2;
    @include large {
        right: 8.6rem;
    }
    @include largetTablet {
        right: 6rem;
    }
    @include desktop {
        right: 1rem;
        width: calc(100% - 2rem);
        top: 58%;
    }
    @include tablet {
        // top: min(25%, 24rem);
        top: 50%;
        transform: translate(0, calc(-50% - 110px));
    }
}

.mobileFestiveIcon {
    width: 9.375rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    img {
        width: 100%;
    }
}

[dir='rtl'] {
    .closeBtn {
        right: initial;
        left: 1.25rem;
    }
    .thumbSliderBox {
        right: initial;
        left: 9%;
        @include large {
            left: 11%;
        }
        @include desktop {
            left: 1rem;
        }
    }
}
