@import '../../../styles/mixins.scss';

button.toggleTrack {
    width: 208px;
    height: 52px;
    border-radius: 9999px;
    background-color: #9d9ea1;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: flex-end;
    transition: none;

    @include tablet {
        width: 140px;
        height: 36px;
        padding: 0 10px;
    }

    &.toggleTrackActive {
        background-color: #e42058;
        justify-content: flex-start;
    }

    p {
        font-size: 14px;
        color: white;
        line-height: 22px;
        font-weight: 300;
        transition: none;
        margin: 0;

        @include tablet {
            font-size: 10px;
        }
    }
}

.toggleKnob {
    height: calc(100% - 4px);
    aspect-ratio: 1/1;
    border-radius: 9999px;
    background-color: #fff;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: left 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;

    &.toggleKnobActive {
        left: calc(100% - 3.125rem);
    }

    img {
        width: 1.5rem;
        height: 1.5rem;
        @include tablet {
            width: 1rem;
            height: 1rem;
        }
    }

    @include tablet {
        height: 32px;
        width: 32px;
        top: 2px;
        left: 2px;
        &.toggleKnobActive {
            left: calc(100% - 34px);
        }
    }
}
