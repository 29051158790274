//-------------------- mixins -----------------------

//media queries
@mixin large {
    @media only screen and (max-width: 1316px) {
        @content;
    }
}
@mixin largetTablet {
    @media only screen and (max-width: 1200px) {
        @content;
    }
}
@mixin desktop {
    @media only screen and (max-width: 1024px) {
        @content;
    }
}
@mixin tablet {
    @media only screen and (max-width: 768px) {
        @content;
    }
}
@mixin mobile {
    @media only screen and (max-width: 480px) {
        @content;
    }
}
