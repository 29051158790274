@import '../../styles/mixins.scss';

.wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    padding: 0 10.25rem;
    @include largetTablet {
        padding: 0 3.5rem;
    }
    @include desktop {
        padding: 0 1rem;
    }
    @media (max-aspect-ratio: 1/1) {
        margin-top: auto;
        margin-bottom: auto;
    }
    @include tablet {
        flex-direction: column-reverse;
        justify-content: center;
        padding: 1rem 1.25rem 0;
        flex: unset;
    }
}

.videobox {
    height: calc(100dvh - 13.375rem);
    max-height: 34.6875rem;
    min-height: 28rem;
    max-width: 38%;
    aspect-ratio: 9/16;
    position: relative;
    border-radius: 0.75rem;
    overflow: hidden;
    will-change: transform;
    z-index: 1;
    cursor: pointer;

    @media (hover: hover) {
        &:hover {
            .playBtn,
            .videoVolume {
                opacity: 1;
            }
        }
    }
    @include desktop {
        height: initial;
        min-height: initial;
        width: 100%;
        max-width: 38%;
    }
    @include tablet {
        width: 100%;
        height: 100%;
        max-width: 60%;
        max-height: initial;
        margin: 1.5rem auto 0;
        border: 0.0625rem solid #780044;
    }
    &:after {
        content: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(
            180deg,
            rgba(60, 0, 38, 0) 40.11%,
            rgba(60, 0, 38, 0.8) 83.1%
        );
        @include tablet {
            content: '';
        }
    }
    .videoVolume {
        opacity: 0;
    }
    .playBtn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        width: 7.5rem;
        height: 7.5rem;
        transition: opacity 0.2s, width 0s, height 0s;

        @include tablet {
            width: 3.75rem;
            height: 3.75rem;
            top: 40%;
            opacity: 1;
            z-index: 2;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }
    .hide {
        opacity: 0;
        height: 100%;
        width: 100%;
        transition: opacity 0.2s, width ease-in 1.6s, height ease-in 1.6s;
        @media (hover: hover) {
            width: 7.5rem;
            height: 7.5rem;
            transition: opacity 0.2s, width 0s, height 0s;
        }
    }
    video {
        object-fit: cover;
        position: absolute;
        width: auto;
        height: 100%;
        min-height: 100%;
        min-width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &::-webkit-media-controls {
            display: none !important;
        }
    }
    a {
        display: none;
        width: 7.65rem;
        height: 3.15rem;
        position: absolute;
        bottom: 2.375rem;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 0.875rem;
        z-index: 2;
        @include tablet {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.descbox {
    width: 61%;
    color: #fff;
    text-align: center;
    padding: 0 1.25rem;
    &.canUseFestiveMode {
        padding: 0 0 0 3.2rem;
        @include tablet {
            padding: 0 1.25rem;
        }
    }
    @include tablet {
        width: 100%;
        padding: 0 1.25rem;
    }
    h1 {
        margin: 0 0 1rem;
        @include desktop {
          white-space: normal;
        }
        @include tablet {
          white-space: pre-wrap;
          margin: 0 0 0.5rem;
        }
    }
    p {
        margin: 0 0 2.625rem;
        white-space: pre-wrap;
        @include desktop {
          white-space: normal;
        }
        @include tablet {
          white-space: pre-wrap;
          margin: 0;
        }
    }
    a {
        @include tablet {
            display: none;
        }
    }
}

.descboxChinese {
    @extend .descbox;
    width: 65%;

    @include tablet {
        width: 100%;
        max-width: 235px;
    }

    @include large {
        h1 {
            max-width: 335px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.links {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 49%;
    gap: 0.25rem;
    display: flex;
    justify-content: center;
    font-weight: 300;
    color: #fff;
    @include tablet {
        position: relative;
        width: 100%;
    }
    a {
        color: inherit;
        text-decoration: none;
        @include tablet {
            font-size: 0.875rem;
        }
        &:hover {
            text-decoration: underline;
        }
    }
}

.toggleContainer {
    display: flex;
    justify-content: center;
    margin-top: -4.1rem;
    margin-bottom: 2.5rem;
    @include tablet {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

[dir='rtl'] {
    .links {
        right: initial;
        left: 0;
    }
}
