@import '../../styles/mixins.scss';

.dialog {
    :global {
        .MuiPaper-root {
            border-radius: 0.75rem;
        }
    }
}

.shareBox {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 60px 40px 40px;
    align-items: center;
    position: relative;
    min-width: 600px;
    @include tablet {
        padding: 24px 40px;
        gap: 12px;
        min-width: unset;
    }
}
.share {
    display: flex;
    align-items: center;
    gap: 8px;
    button {
        transition: 0.2s;
        &:hover {
            opacity: 0.8;
        }
    }
    a {
        padding: 0;
        transition: 0.2s;
        &:hover {
            opacity: 0.8;
        }
    }
}

.closeModalButton {
    position: absolute;
    color: #1f212b;
    top: 25px;
    right: 25px;
    font-size: 1.5rem;
    cursor: pointer;
    @include tablet {
        top: 10px;
        right: 15px;
    }
}

.shareTitle {
    font-size: 40px;
    color: #1f212b;
    font-weight: 500;
    text-align: center;
    line-height: 56px;
    @include tablet {
        font-size: 24px;
    }
}

.buttonsWithImgIcons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    img {
        width: 32px;
        height: 32px;
    }

    @include tablet {
        width: 32px;
        height: 32px;
        img {
            width: 18px;
            height: 18px;
        }
    }
}

.weiboShareBtn {
    background-image: linear-gradient(90deg, #ffd737, #ffeda5);
    border-radius: 50%;
}

.copyClipboardBtn {
    background-color: #7f7f7f;
    border: 2px solid #7f7f7f;
    border-radius: 50%;
    &:global {
        &.active {
            background-color: #fff;
            border-color: #64003a;
        }
    }
}

.btn {
    padding: 8px 16px;
}
