@import '../../styles/mixins.scss';

.header {
    width: 100%;
    position: absolute;
    top: 2rem;
    left: 0;
    right: 0;
    z-index: 2;
    @include tablet {
        top: 1.5rem;
    }
}

.wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.col {
    display: flex;
    width: calc(50% - 6.25rem);
    @include tablet {
        width: calc(50% - 3.75rem);
    }
}

.backbtn {
    display: flex;
    width: 2.3125rem;
    height: 2.3125rem;
    @include tablet {
        height: 1.375rem;
        width: 1.375rem;
    }
}

.logo {
    display: flex;
    height: auto;
    width: 13.375rem;
    @include tablet {
        width: 7.125rem;
    }
    img {
        height: 100%;
        width: auto;
    }
}

.ltrContent {
    font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif !important;
}

.rtlContent {
    font-family: 'Almarai', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif !important;
}

.dropdown {
    position: relative;
    color: #fff;
    margin: 0 0 0 auto;
    button {
        padding: 0 2.25rem 0 0;
        font-size: 1.5rem;
        font-weight: 300;
        line-height: 150%;
        @include tablet {
            padding: 0 1.875rem 0 0;
            font-size: 0.875rem;
        }
        svg {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0, -50%);
            width: 1.4375rem;
            height: 0.75rem;
            pointer-events: none;
            @include tablet {
                width: 0.9375rem;
                height: 0.5rem;
            }
        }
    }
}
.selectMenu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 11.5rem;
    border-radius: 1rem;
    background-color: #fff;
    color: #000;
    padding: 0.75rem 0;
    margin: 0.75rem 0 0 0;
    transition: 0.2s;
    opacity: 0;
    visibility: hidden;
    @include tablet {
        width: 8.75rem;
    }
    button {
        width: 100%;
        font-size: 1.25rem;
        padding: 0.5rem 1.5rem;
        text-align: left;
        @include tablet {
            font-size: 0.875rem;
            padding: 0.25rem 1rem;
        }
    }
}
.dropdownOpened {
    opacity: 1;
    visibility: visible;
    button {
        svg {
            transform: translate(0, -50%) scale(1, -1);
        }
    }
    .selectMenu {
        opacity: 1;
        visibility: visible;
    }
}
.itemSelected {
    background-image: url(../../assets/icons/check.svg);
    background-size: 1.5rem;
    background-position: calc(100% - 1.5rem) 50%;
    background-repeat: no-repeat;
    @include tablet {
        background-size: 1rem;
    }
}

.appClicked {
    * {
        &:focus-visible {
            outline: none !important;
        }
    }
}

[dir='rtl'] {
    .dropdown {
        margin: 0 auto 0 0;
    }
    .backbtn {
        transform: scale(-1, 1);
    }
    .selectMenu {
        left: 0;
        right: initial;
        button {
            text-align: right;
        }
    }

    .itemSelected {
        background-position: 1.5rem 50%;
    }
}
