@import '../../styles/mixins.scss';

.uploadedPictureBox {
    width: 47%;
    position: relative;
    padding: 0.875rem;
    background-image: url('../../assets/icons/img-corners.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    margin: auto 0;
    transition: 0.2s;
    @include tablet {
        width: 84%;
        padding: 0.5rem;
    }
    &.loading {
        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            background-image: linear-gradient(
                to bottom,
                rgba(170, 184, 190, 0),
                rgba(255, 255, 255, 0.6)
            );
            animation: scanning 2.5s linear infinite alternate;
        }
        @keyframes scanning {
            0% {
                height: 0;
            }
            25% {
                height: 100%;
            }
            50% {
                top: initial;
                bottom: 0;
                height: 0;
            }
            75% {
                height: 100%;
            }
            100% {
                height: 0;
            }
        }
    }
    &.approved {
        padding: 0;
        background-image: none;
        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: #2fc95566;
            background-image: url('../../assets/icons/green-check.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 6.125rem;
            border-radius: 0.5rem;
            @include tablet {
                background-size: 2.75rem;
            }
        }
    }
    &.denied {
        padding: 0;
        background-image: none;
        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: #da102e99;
            background-image: url('../../assets/icons/red-exclamation.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 6.125rem;
            border-radius: 0.5rem;
            @include tablet {
                background-size: 2.75rem;
            }
        }
    }
}

.pictureSkinTypeBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 23rem;
    gap: 8px;
    p {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: white;
    }

    .pictureSkinTypes {
        position: relative;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        border-radius: 20px;
        border: solid 1px #ffffff;
        width: 100%;
        height: 50px;
    }

    .pictureSkinType {
        height: 100%;
        cursor: pointer;
        z-index: 1;
        &:first-child {
            border-bottom-left-radius: 20px;
            border-top-left-radius: 20px;
        }
        &:last-child {
            border-bottom-right-radius: 20px;
            border-top-right-radius: 20px;
        }
        &.selected {
            box-shadow: 0 0 0 2px #2FC955;
            z-index: 10;
        }
    }

    @include tablet {
        margin-bottom: 1.5rem;
        max-width: 250px;
        p {
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
        }
        .pictureSkinTypes {
            height: 30px;
            border-radius: 10px;
        }
        .pictureSkinType {
            &:first-child {
                border-bottom-left-radius: 10px;
                border-top-left-radius: 10px;
            }
            &:last-child {
                border-bottom-right-radius: 10px;
                border-top-right-radius: 10px;
            }
        }
    }
}

.uploadedPicture {
    position: relative;
    width: 100%;
    padding: 0 0 117%;
    border-radius: 0.5rem;
    overflow: hidden;
    img {
        object-fit: cover;
        position: absolute;
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.analyzingBox {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 53%;
    // gap: 5rem;
    gap: 60px;
    color: #fff;
    text-align: center;
    margin: auto;
    padding: 0 5.125rem;
    @include desktop {
        padding: 0 1.5rem;
    }
    @include tablet {
        flex: initial;
        max-width: initial;
        width: 100%;
        padding: 0 2.875rem;
        margin: 0;
    }
    :global {
        .h1 {
            margin: 0;
        }
    }
    p {
        max-width: 100%;
        margin: 1rem 0 0 0;
        @include tablet {
            margin: 0.75rem 0 0;
        }
    }
}

.analyzingBoxChinese {
  @extend .analyzingBox;
    padding: 0 5rem;
  p {
    max-width: 33rem;
    width: 33rem;
      @include desktop {
          width: initial;
      }
  }
}

.analyzingBoxFrench {
    @extend .analyzingBox;
    padding: 0 3.125rem;
    p {
        max-width: 36rem;
        width: 100%;
    }
}

.analyzingBoxGerman {
    @extend .analyzingBox;
    padding: 0;

    p {
        max-width: 600px;
    }

    @include desktop {
      p {
        max-width: 600px;
      }
    }

    @include tablet {
      padding: 0;

      p {
        max-width: 300px;
      }
    }
}

.analyzingBoxPortuguese {
    @extend .analyzingBox;

    .headerNotApproved {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 630px;
        width: 120%;

        p {
            max-width: 630px;
            width: 115%;
        }

        @include desktop {
            max-width: 380px;
            p {
                max-width: 380px;
            }
        }

        @include tablet {
            max-width: 370px;
            width: 100%;
            p {
                max-width: 370px;
                width: 100%;
            }
        }
    }

    @include tablet {
      padding: 0;
    }
}

.analysingTitle {
    margin: 0 0 2.625rem !important;
    @include tablet {
        margin: 0 !important;
    }
}
.analysingSlideActions {
    flex-direction: row-reverse;
    padding: 0;
    margin: 1.375rem 0;
}
.slideActionsClassName {
    padding: 0;
    &:first-of-type {
        border: none;
    }
    &:last-of-type {
        border-right: 1px solid #fff;
    }
}
.mobileFooter {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
}
.btnGroup {
    width: 100%;
    display: flex;
    gap: 1.5rem;
    align-items: center;
    flex-direction: column;
    @include tablet {
        gap: 0.75rem;
    }
}
.uploadBtnBox {
    position: relative;
    max-width: 23.5rem;
    width: 100%;
    input {
        position: absolute;
        width: 100%;
        height: 100%;
        color: transparent;
        left: 0;
        z-index: -10;
    }
    input[type='file']::file-selector-button {
        display: none;
    }
}

.uploadBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.875rem;
    color: #fff;
    border-radius: 2.375rem;
    padding: 0.875rem;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;
    border: 0.125rem solid #fff;
    transition: 0.4s;
    @include tablet {
        border-width: 1px;
        padding: 0.75rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
    &:hover {
        background-color: #fff;
        color: #780044;
        svg {
            fill: #64003a;
        }
    }
    svg {
        fill: #fff;
        width: 1.875rem;
        height: 1.875rem;
        transition: 0.2s;
        @include tablet {
            width: 1.25rem;
            height: 1.25rem;
        }
    }
}

[dir='rtl'] {
    .slideActionsClassName {
        padding: 0;
        &:first-of-type {
            border: none;
        }
        &:last-of-type {
            border-left: 1px solid #fff;
            border-right: none;
        }
    }
}
