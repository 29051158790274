@import '../../styles/mixins.scss';

.dialog {
    :global {
        .MuiPaper-root {
            width: 100%;
            max-width: 51.875rem;
            padding: 2.5rem;
            border-radius: 0.75rem;
            margin: 1rem;
            @include tablet {
                max-height: 75vh;
                padding: 1rem;
                border-radius: 1rem 1rem 0 0;
                margin: 0;
            }
        }
        .MuiDialogTitle-root {
          @include tablet {
            font-size: 22px;
            line-height: 30.8px;
          }
        }
    }
    p {
        font-weight: 300;
        line-height: 1.5rem;
        color: #4a525d;
        margin: 0 0 1.5rem;
        &:last-of-type {
            margin: 0;
        }
        @include tablet {
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1.125rem;
            margin: 0 0 1.125rem;
        }
    }
}
.submitBtn {
    margin: 2rem auto 0;
    align-items: center;
    @include tablet {
        margin: 1.5rem auto 0;
    }
}

.closeBtn {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 1.5rem;
    @include tablet {
        padding: 1.25rem;
    }
    img {
        width: 0.9375rem;
        height: 0.9375rem;
    }
}

.text {
    // flex: 1;
    max-height: calc(100dvh - 20rem);
    overflow: auto;
    @include tablet {
        padding: 0.9375rem;
    }
}

.recaptchaContainer {
    padding: 12px 24px 24px;
    @include tablet {
        padding: 8px;
        display: flex;
        justify-content: center;
    }
}

.reCaptchaLegalNotice {
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #4a525d;
    margin: 1.5rem 0  0;
    text-align: center;
    word-break: keep-all;
    @include tablet {
        margin: 1.125rem 0 0;
    }
}

[dir='ltr'] {
    li {
        margin-right: 0 !important;
        margin-left: 2rem!important;
    }
}

[dir='rtl'] {
    .closeBtn {
        right: initial;
        left: 0;
    }

    li {
        margin-left: 0!important;
        margin-right: 2rem!important;
    }
}
